"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _effects = require("redux-saga/effects");
var _commonsApi = require("@sm360/commons-api");
var _deskingError = require("@sm360/desking-error");
var _selectors = require("../../selectors");
var _actions = require("../../actions");
var _customer = require("../../../constants/customer");
/**
 */
function* getCustomer() {
  const {
    organizationId,
    organizationUnitId,
    location,
    lang
  } = yield (0, _effects.select)(_selectors.getConfig);
  const leadId = yield (0, _effects.select)(_selectors.getLeadId);
  const contact = yield (0, _effects.select)(_selectors.getContact);
  const {
    firstname,
    postalCode,
    email1,
    province,
    address,
    address2,
    city,
    country,
    sexId,
    mobilePhone,
    middlename,
    lastname,
    businessName,
    dateOfBirth
  } = contact;
  const applicantPayload = {
    type: _customer.APPLICATION_TYPE.INDIVIDUAL,
    birthDate: dateOfBirth,
    name: {
      genderCode: sexId,
      givenName: firstname,
      middleName: middlename,
      familyName: lastname,
      businessName
    },
    address: {
      addressLine: [address, address2],
      city,
      stateOrProvince: province,
      country,
      postalCode
    },
    contact: {
      mobilePhone,
      homeEmail: email1
    }
  };
  const queryParams = {
    orgId: organizationId,
    orgUnitId: organizationUnitId,
    location,
    lang,
    mainApplicant: true
  };
  try {
    let response = yield (0, _effects.call)(_commonsApi.ShowroomService.desking.getCustomer, leadId, queryParams);
    yield (0, _effects.put)(_actions.Creators.setCustomer(response));
    const hasCreditApplication = yield (0, _effects.select)(_selectors.getHasCreditApplication);
    if (!hasCreditApplication) {
      yield (0, _effects.put)(_actions.Creators.updateCustomer({
        applicant: {
          ...applicantPayload
        },
        coApplicant: {}
      }));
    }
  } catch (error) {
    yield (0, _effects.put)(_deskingError.errorActions.handleError(error, 'getCustomer'));
  }
}
var _default = exports.default = getCustomer;