"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Types = exports.Creators = void 0;
var _reduxsauce = require("reduxsauce");
const {
  Types,
  Creators
} = (0, _reduxsauce.createActions)({
  selectVehicleMatch: ['vehicle'],
  applyNegativeEquity: ['selectedType', 'reimbursementAmount'],
  loadQuoteSuccess: ['quote'],
  setLoadingSuccess: [],
  setUserRights: ['userRights'],
  setUserRoles: ['userRoles'],
  setOrgRights: ['orgRights'],
  setQuoteChanged: ['isQuoteChanged', 'quoteChangeType'],
  setNewMatchSelected: ['isNewMatchSelected'],
  setMatchVehicle: ['selectedVehicle'],
  setReadOnly: ['isReadOnly'],
  setDealerInfo: ['dealerInfo'],
  setLeadInfo: ['leadInfo'],
  setOpenQuickEdit: ['openQuickEdit'],
  updatePaymentOptionsSuccess: ['response'],
  updateTaxesIncluded: ['hasTaxesIncluded'],
  updateTransactionDetailsSuccess: ['response'],
  resetPayAdjustmentValidation: [],
  resetPayAdjustmentValidationSuccess: [],
  getDealerInfo: [],
  getLeadInfo: [],
  assignLead: ['personnelType', 'userId'],
  updateTransactionToken: ['transactionToken'],
  setVisibleCustomer: ['quote'],
  setCustomer: ['customer'],
  setIsCoApplicantSelected: ['isCoApplicantSelected'],
  // Sagas
  changeDesiredApr: ['calculationTargetValue'],
  changeDesiredPayment: ['targetType', 'value', 'handleIsValid'],
  changePaymentOptions: ['options'],
  setCalculatedAdjustments: ['calculatedAdjustments'],
  changePaymentCard: ['options', 'index'],
  changePaymentCardCashDown: ['cashDown', 'cashDownIndex', 'isCashDownSelected', 'financeOptionsIndex'],
  changePaymentCardSelected: ['options'],
  changePlan: ['financePlan', 'purchaseMethod', 'isSelected', 'index'],
  changeSelectedQuote: ['selectedVehicle'],
  initializeStore: ['queryParams'],
  refreshQuote: [],
  selectCashPlan: [],
  selectPayment: ['cashDown', 'term', 'index'],
  selectPlan: ['index'],
  transactionAmountValidation: ['itemData'],
  commissionAmountValidation: ['itemData'],
  updateProfitRoll: ['targetType', 'value', 'handleIsValid'],
  updateBlendedRateRoll: ['calculationOverAmount', 'calculationOverAmountApr'],
  updateDueAtDeliveryRoll: ['targetType', 'value', 'handleIsValid'],
  updateAmountToPayRoll: ['targetType', 'value', 'handleIsValid'],
  updateQuoteInformation: ['options'],
  validateProfitRollChange: ['targetType', 'value', 'changeType'],
  validatePaymentChange: ['targetType', 'value', 'changeType'],
  validateDueAtDeliveryRollChange: ['targetType', 'value', 'changeType'],
  validateAmountToPayRollChange: ['targetType', 'value', 'changeType'],
  initializeCalculatedAdjustments: ['targetType', 'value'],
  reloadTradeIn: [],
  setQuote: ['quote'],
  setQuoteFlags: ['params'],
  setItemFlags: ['params'],
  getCustomer: [],
  updateCustomer: ['customer'],
  getCreditApplicationDecision: [],
  getContractValidationDecision: [],
  sendToSerti: [],
  submitRDR: [],
  createDealFromQuote: ['quote'],
  deleteDeal: [],
  // Matches
  loadMatches: [],
  loadMatchesSuccess: ['perfectList', 'similarList', 'selectedVehicle'],
  // Commission
  loadCommission: [],
  loadCommissionSuccess: ['commission'],
  setDisplayCostAndProfit: ['displayCostAndProfit'],
  addCommissionAdjustment: ['items', 'commissionAdjustmentType'],
  deleteCommissionAdjustment: ['item'],
  // Residual
  changeResidualAdjustment: ['category', 'value'],
  changeResidualValue: ['category', 'value'],
  // Taxable amount
  changeTaxableAmount: ['category', 'amount'],
  // Items
  addDeleteItem: ['item', 'picture'],
  addTransactionItems: ['items'],
  reloadTabItemsSuccess: ['name', 'items'],
  selectTabItems: ['index'],
  editTransactionItems: ['items'],
  // Protection
  reloadTabProtection: ['reloadFNIItems'],
  reloadTabProtectionSuccess: ['name', 'items'],
  setFNIItemsError: ['fniType', 'error', 'errMsg'],
  selectTabProtection: ['index'],
  setIsFetchingFNIItems: [],
  setIsFetchingFNIItemsDone: [],
  setFNIItemsAreLoaded: ['fniType'],
  openInvalidResponseModal: ['title', 'messages'],
  closeInvalidResponseModal: [],
  selectFNIItemPlan: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'isSelected'],
  selectFNIItemPlanSuccess: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'isSelected'],
  updateFNIItemPlanPricing: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'payload'],
  updateFNIItemPlanPricingSuccess: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'payload'],
  updateFNIItemPlanCoverageParameters: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'payload'],
  updateFNIItemPlanCoverageParametersSuccess: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'payload'],
  updateFNIItemPlanSuccess: ['fniType', 'contentType', 'persistentId', 'planPersistentId', 'validationItem'],
  onBookFNIItemsClick: ['fniType'],
  onBookFNIItemsClickSuccess: ['fniType', 'bookedItems'],
  // Validation
  applyConflictResolution: ['negociationTransaction'],
  cancelConflict: [],
  handleConflictValidation: ['response', 'picture'],
  handlePayAdjustmentValidation: ['response', 'changeType'],
  // Quote
  updateQuoteStatus: ['status'],
  updateQuoteStatusDone: [],
  setCustomerVisibility: ['isVisible'],
  // General use for all changes to quote (loading, status update, etc.)
  setIsQuoteUpdating: [],
  setIsQuoteUpdatingDone: [],
  // Vehicle
  reloadVehicleSuccess: ['vehicle'],
  // Dealer bar
  changeSaleType: ['saleType'],
  changeProvince: ['province'],
  changeTaxPlan: ['taxPlan'],
  // Contact
  updateContact: ['requestBody', 'checkIfPassed'],
  reloadContactSummary: [],
  reloadContactSummarySuccess: ['contact'],
  loadDMSAccountMatches: [],
  loadDMSAccountMatchesSuccess: ['DMSAccountMatches'],
  loadDMSCustomer: ['clientNumber'],
  loadDMSCustomerSuccess: ['DMSCustomer'],
  linkDMSAccount: ['clientNumber'],
  linkDMSAccountSuccess: ['client'],
  linkMatchDMSAccount: ['clientNumber'],
  createDMSAccount: ['clientNumber'],
  createDMSAccountSuccess: ['client'],
  // Incentives
  reloadIncentives: [],
  reloadIncentivesSuccess: ['incentives'],
  changeIncentive: ['incentive', 'quoteFlagsParams'],
  // Trade-in
  reloadTradeInSuccess: ['tradeIn'],
  setTradeInWidget: ['tradeInWidget'],
  // Credit-Application (and Contract-Validation)
  setCreditApplicationDecision: ['decision'],
  setContractValidationDecision: ['decision'],
  setIsCreditApplicationDecisionRefreshing: ['isCreditApplicationDecisionRefreshing'],
  setIsContractValidationDecisionRefreshing: ['isContractValidationDecisionRefreshing'],
  // Financing
  updateFinancingDates: ['quoteRevision'],
  setFinancingDates: ['dates'],
  setFinancingDatesChanged: ['isFinancingDatesChanged'],
  // Printing
  getTransactionPrintList: [],
  setTransactionPrintList: ['transactionPrintList'],
  transactionPrint: ['formInstanceId', 'customFields', 'checkedLang'],
  getTransactionPrintCustomFields: ['formInstanceId', 'checkedLang'],
  setTransactionPrintCustomFields: ['transactionPrintCustomFields'],
  setIsTransactionListLoading: [],
  setIsTransactionListLoadingDone: [],
  // Accounting
  getAccTemplates: [],
  getAccTemplateData: [],
  setAccTemplates: ['templates'],
  setAccSelectedTemplate: ['templateId'],
  setAccTemplateData: ['templateData'],
  // Security deposit
  getMsdRoll: [],
  updateMsdRoll: ['options'],
  setMsd: ['msd'],
  // Location outside province
  getLocationOutsideProvince: ['saleIsOutsideLocation', 'registrationLocation', 'registrationLocationTaxPlan'],
  // Quote status and vehicle status unmatch (trigger conflict modal)
  setShowConflict: ['showConflict'],
  // Forms
  reloadTabForms: [],
  reloadTabFormsSuccess: ['dealJacket'],
  updateTabForms: ['updatedForm', 'fromPrint', 'checkedLang'],
  updateTabFormsSuccess: ['dealJacket'],
  selectTabForms: [],
  setTabFormsIsPrinting: ['isPrinting'],
  setDealJacketForm: ['updatedForm'],
  formPrint: ['formInstanceId', 'customFields', 'checkedLang'],
  uploadDealJacketForm: ['formInstanceId', 'form'],
  loadDealJacketForm: ['uploadedFormId'],
  addDealJacketForm: ['description'],
  deleteDealJacketForm: ['uploadedFormId'],
  deleteDealJacketForms: ['uploadedFormsIds']
});
exports.Creators = Creators;
exports.Types = Types;