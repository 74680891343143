"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.en = void 0;
const en = exports.en = {
  authentication: {
    ERROR: 'Your authentication in the system has expired. Please proceed to reauthenticate to regain access.',
    IAT_IS_IN_FUTURE: 'The authentication service cannot issue a valid token because it appears that your system clock is not automatically set.',
    TOKEN_ISSUE_TIME: 'Your token was issued on the <0></0> but your system time is different: <1></1>.'
  },
  commons: {
    yes: 'Yes',
    no: 'No',
    and: 'and',
    or: 'or',
    NA: 'N/A',
    INACTIVE: 'Inactive',
    DEALER: 'Dealer',
    actions: {
      ADD: 'Add',
      APPLY: 'Apply',
      ARCHIVE: 'Archive',
      BACK: 'Back',
      BACK_TO_SUGGESTIONS: 'Back to suggestions',
      BUY: 'Buy',
      CANCEL: 'Cancel',
      CLONE: 'Clone',
      CLOSE: 'Close',
      CREATE: 'Create',
      CONFIRM: 'Confirm',
      CURRENTLY_ACTIVE: 'Currently active',
      DELETE: 'Delete',
      DESELECT: 'Deselect',
      DISPLAY: 'Display',
      DUPLICATE: 'Duplicate',
      EDIT_CONTACT: 'Open Contact',
      EDIT_CONTACT_NEWTAB: 'Open Contact in a New Tab',
      EDIT_LEAD: 'Open Lead',
      EDIT_DEAL: 'Open Deal',
      EDIT_NEWTAB: 'Open in a New Tab',
      EDIT: 'Edit',
      ENTER: 'Enter',
      FIND_DUPLICATE: 'Find Duplicate(s)',
      HIDE: 'Hide',
      IMPORT: 'Import',
      LOAD: 'Load',
      MERGE: 'Merge',
      MINIMIZE: 'Minimize',
      NEXT: 'Next',
      OPEN: 'Open',
      OPEN_IN_NEW_TAB: 'Open in new tab',
      PASTE: 'Paste',
      PRINT: 'Print',
      REFINE: 'Refine',
      REFRESH: 'Refresh',
      REMOVE: 'Remove',
      REPLY: 'Reply',
      RESTORE: 'Restore',
      RETRY: 'Retry',
      RESET: 'Reset',
      RUN: 'Run',
      SAVE: 'Save',
      SEARCH: 'Search',
      SELECT_ALL_ELEMENT: 'Select all {{objectType}}',
      SELECT_ALL: 'Select All',
      SELECT: 'Select',
      SEND: 'Send',
      SHOW: 'Show',
      SYNC: 'Synchronize',
      TRIGGER: 'Trigger',
      UNSELECT_ALL: 'Unselect All',
      UPLOAD: 'Upload'
    },
    actionbar: {
      SKIP_TITLE: 'Skip to action bar',
      BAR_TITLE: 'Action bar'
    },
    errors: {
      401: {
        title: 'Unauthenticated access',
        description: 'You must be logged in to access this page.'
      },
      403: {
        title: 'Unauthorized access',
        description: 'You probably do not have the rights to access this page. You can contact the Support team for more information.'
      },
      404: {
        title: 'Page not found',
        description: 'The page you are trying to access cannot be found.'
      },
      TOO_MANY_REQUESTS: {
        title: 'Too Many Requests',
        description: 'We have detected suspicious use of the CRM (exceeding the limit of requests allowed per hour), please reach out to support.'
      },
      UNEXPECTED: {
        title: 'It looks like there is an unexpected error within the application.',
        description: 'Please contact an administrator and/or the support team with the error details.',
        CTA: 'Try again',
        view_details: 'View error details'
      },
      GO_TO_HOMEPAGE: 'Go to Homepage'
    },
    language: {
      ENGLISH: 'English',
      FRENCH: 'French'
    },
    rightsWarning: {
      RIGHT_REQUIRED: 'You are not allowed to perform this action: {{right}}',
      Admin: 'Only administrators are allowed to access this section.',
      Beta: 'This feature is restricted to Beta users.',
      ContactAccess: 'You are not allowed to access contact details.',
      CrmDealViewDetail: 'You are not allowed to access deal details.',
      CrmDeleteActivity: 'You are not allowed to delete activities.',
      CrmImportContactListAccess: 'You are not allowed to import a list of contacts.',
      CrmProAccess: 'You are not allowed to access the CRM section.',
      CrmProAdministrationSectionAccess: 'You are not allowed to access administration tools.',
      CrmProEditEmailTemplates: 'You are not allowed to manage email templates.',
      CrmProModifyLeadDeliveredDate: "You are not allowed to modify leads' sold or delivered date.",
      CrmProModifyLeadSoldDate: "You are not allowed to modify leads' sold or delivered date.",
      CrmSmsFeatureUse: 'You are not allowed to send SMS.',
      CrmSmsNumbersManagement: 'You are not allowed to configure SMS numbers.',
      DeleteContact: 'You are not allowed to delete contacts.',
      DeleteLead: 'You are not allowed to delete leads.',
      DeskingCanUpdateDealStatus: 'You are not allowed to change the status of deals.',
      EditBdcConfiguration: 'You are not allowed to manage the BDC configurations.',
      EditCampaign: 'You are not allowed to access campaign details.',
      EditLead: 'You are not allowed to edit leads.',
      EditRestrictedLeadStatus: 'You are not allowed to edit a lead with a restricted lead status.',
      IvrAudioPlayer: 'A recording of this call is available but you do not have permission to listen to it.',
      IvrEnabled: 'You are not allowed to manage PhoneTrack numbers.',
      IvrInbound: 'You are not allowed to receive phone calls.',
      IvrOutbound: 'You are not allowed to make phone calls.',
      leadHasDeskingQuote: 'The Sold or Delivery date can be changed from the Quote.',
      LeadStatusConfigManagement: 'You are not allowed to configure lead statuses.',
      ManageAntiSpam: 'You are not allowed to access this section.',
      ManageDocumentLead: 'You are not allowed to access this section.',
      ModuleLeadEnabled: "You are not allowed to access leads' informations.",
      ReadAllFollowEvent: "You are not allowed to see other's people tasks.",
      ReadFollowLead: "You are not allowed to see other's people leads.",
      ReAssignLead: 'You are not allowed to re-assign leads.',
      ReportingSharedConfigManagement: 'You are not allowed to manage shared configurations.',
      ViewLead: 'You are not allowed to view leads.',
      ViewEmail: 'You are not allowed to view email content.',
      ViewSms: 'You are not allowed to view SMS messages.'
    },
    crud: {
      ENTRY: 'entry',
      ENTRIES: 'entries',
      ADDNEW: 'Add an Entry',
      SHOWARCHIVED: 'Show Archived Entries',
      COLUMNS_TOGGLE_INSTRUCTIONS: "Toggle column visibility or reorder them by drag n'drop",
      LOADING: 'Loading',
      NOENTRY: 'No Entry',
      ACTIONS: 'Actions',
      CREATED: 'New Entry Created',
      UPDATED: 'Entry Updated',
      DELETED: 'Entry Deleted'
    },
    filters: {
      OPEN_FILTERS: 'Show Advanced Search',
      CLOSE_FILTERS: 'Hide Advanced Search',
      RESET_FILTERS: 'Reset Filters'
    },
    selectMultiple: {
      label: {
        search: 'Search',
        selectbutton: 'Select all',
        clearbutton: 'Unselect all',
        selection: 'selections',
        allSelected: 'All selected',
        noSelection: 'None',
        anonymous: 'Anonymous',
        selected: 'Selected',
        available: 'Available'
      }
    },
    validation: {
      required: 'This field is required',
      tel: 'Please enter a valid phone number',
      email: 'Please enter a valid email',
      samePassword: 'This password is different from the first one',
      postalCode: 'Invalid postal code (A1A 1A1 or 00000)'
    },
    COUNTRY: 'Country',
    countryCode: {
      CA: 'Canada',
      US: 'USA'
    },
    months: {
      JANUARY: 'January',
      FEBRUARY: 'February',
      MARCH: 'March',
      APRIL: 'April',
      MAY: 'May',
      JUNE: 'June',
      JULY: 'July',
      AUGUST: 'August',
      SEPTEMBER: 'September',
      OCTOBER: 'October',
      NOVEMBER: 'November',
      DECEMBER: 'December'
    },
    dateRanges: {
      TODAY: 'Today',
      YESTERDAY: 'Yesterday',
      LAST_SEVEN_DAYS: 'Last Seven Days',
      LAST_FOURTEEN_DAYS: 'Last Fourteen Days',
      LAST_THIRTY_DAYS: 'Last Thirty Days',
      LAST_120_DAYS: 'Last 120 Days',
      CURRENT_WEEK: 'Current Week',
      CURRENT_MONTH: 'Current Month',
      CURRENT_YEAR: 'Current Year',
      LAST_WEEK: 'Last Week',
      LAST_MONTH: 'Last Month',
      LAST_YEAR: 'Last Year',
      NEXT_SEVEN_DAYS: 'Next Seven Days',
      NEXT_FOURTEEN_DAYS: 'Next Fourteen Days',
      NEXT_THIRTY_DAYS: 'Next Thirty Days',
      NEXT_MONTH: 'Next Month',
      NEXT_SIX_MONTHS: 'Next Six Months',
      CUSTOM: 'Custom'
    }
  },
  app: {
    WELCOME_MESSAGE: 'Welcome',
    NO_ACCESS_APP: 'You may not have the rights to access this application.',
    NO_ACCESS_SECTION: 'You may not have the rights to access this section.',
    FETCH_DATA: {
      0: 'Fetching data',
      1: 'It may take a while...'
    },
    NEW_MESSAGE: '{{count}} New Message',
    NEW_MESSAGES: '{{count}} New Messages',
    update: {
      CTA: 'Update',
      CTA_long: 'Update and Reload the application',
      label: 'A new version ({{version}}) of the application is available'
    },
    legal: {
      PRIVACY: 'Privacy Policy',
      PRIVACY_URL: '/privacy-policy.html',
      TERMS: 'Terms of Use',
      TERMS_URL: '/terms-of-use.html'
    },
    support: {
      HELP_CENTER: 'Help Center',
      BY_PHONE_TITLE: 'Priority Support',
      BY_PHONE_DESCRIPTION: 'For immediate assistance, contact our support team at the following number: 1-833-360-0485',
      BY_TICKET_TITLE: 'Help Center',
      BY_TICKET_DESCRIPTION: 'Access your portal to create a ticket for our support team.',
      BY_TICKET_CTA: 'New Ticket'
    },
    PROMO: 'Powered by ',
    user: {
      SIGNIN: 'Log in',
      SIGNOUT: 'Sign Out',
      GRAVATAR_CTA: 'Add a profile picture with Gravatar.com.',
      GRAVATAR_HELP_CTA: 'Learn more',
      CHECK_RIGHTS: 'Checking access rights',
      FETCH_DATA: 'Fetching data',
      CTA_TOKEN: 'Copy to Clipboard',
      TOKEN_COPY_SUCCESS: 'Your ID Token has been copied to your clipboard.',
      TOKEN_COPY_ERROR: 'Your ID Token has not been copied to your clipboard.',
      CTA_CHANGE_ORGANIZATION: 'Change Organization',
      caracters: '12 or more characters',
      letters: 'Upper and lower case letters',
      numbers: 'At least one number',
      special: 'Contains at least one symbol (~`! @#$%^&*()_-+={[}]|\\:;"\'<,>.?/)',
      confirm: 'Password must be the same',
      updatePassword: 'Update Password',
      modifyPassword: 'Modify Password',
      mustHave: 'Your Password must have:',
      password: 'Password',
      newPassword: 'New Password',
      confirmPassword: 'Confirm Password',
      CHANGE_PASSWORD_FAIL: 'Wrong password',
      CHANGE_PASSWORD_SUCCESS: 'Your Password has been changed',
      PROFILE: 'Profile',
      TITLE_MAIN_SETTINGS: 'Main settings',
      FULL_NAME: 'Full name',
      EMAIL: 'Email',
      SECURITY: 'Security',
      PHONE: 'Phone',
      PHONE_NUMBERS: 'Phone Numbers',
      NOTIFICATIONS: 'Notifications',
      PREFERENCES: 'Preferences'
    },
    nav: {
      ACTIVITIES: 'Activities',
      ACTIVITY_DASHBOARD: 'Activity Dashboard',
      ADMINISTRATION_SHORT: 'ADMIN',
      ADMINISTRATION: 'Admin Tools',
      APPOINTMENTS: 'Appointments',
      AUDIT_DESCRIPTION: 'View Audit Trails',
      AUDIT: 'Audit Trail',
      BDC_CONFIG: 'BDC Configuration',
      BRAND_COMPLIANCES: 'Brand Compliances',
      BUILDER: 'Build a Vehicle',
      BULKINSERTS_DESCRIPTION: 'Manage bulk inserts',
      BULKINSERTS: 'Bulk Inserts',
      CALENDAR: 'Calendar',
      CAMPAIGN_STATS: 'Statistics',
      CAMPAIGN: 'Campaign Manager',
      COLANDER_DESCRIPTION: 'List of script filters for Elasticsearch',
      COLANDER: 'Colander',
      CONTACTS: 'Contacts',
      DASHBOARD: 'Overview',
      DEAL_CONFIGURATION_DESCRIPTION: 'Manage deal import configurations',
      DEAL_CONFIGURATION: 'Deal Import Configuration',
      DEAL_SERVICE_CONFIGURATION: 'Deal/service Import Configuration',
      DEALER_REBATES: 'Dealers Rebates',
      DEALERS_DESCRIPTION: 'Group and Dealers Management',
      DEALERS: 'Dealers',
      DEALS: 'Deals',
      DELIVERY_DASHBOARD: 'Delivery Dashboard',
      DESKING_INVENTORY_REPORT: 'Inventory Report',
      DESKING_QUOTES: 'Quotes',
      DESKING_SALES_REPORT: 'Sales Report',
      DESKING_VEHICLES: 'Search Vehicle',
      DESKING: 'Desking',
      DOCUMENTS_SHORT: 'DOCS',
      DOCUMENTS: 'Documents',
      DOWNLOADS_DESCRIPTION: 'View downloads',
      DOWNLOADS: 'Download Centre',
      EMAIL_NOTIFICATIONS_DESCRIPTION: 'Retrieve info from SES store',
      EMAIL_NOTIFICATIONS: 'Email Notifications',
      EVENTS_CONFIG: 'Events Configuration',
      EXPORT_CONFIG_DESCRIPTION: 'Manage Inventory Export FTP configuration',
      EXPORT_CONFIGURATION: 'Inventory Export',
      EXPORT_REPORTS: 'Export Reports',
      EXTDATA_EXPORT: 'External Data Export',
      GENERAL_SETTINGS: 'General Settings',
      GLOSSARY: 'Glossary',
      IMPORT_CONFIG_DESCRIPTION: 'Manage Inventory Import FTP configuration (xmsdaemonic)',
      IMPORT_CONFIGURATION: 'Inventory Import',
      IMPORT_EXPORT_CONFIGURATION: 'Import/Export FTP Configuration',
      IMPORT_TYPES_DESCRIPTION: 'Import-Types Management',
      IMPORT_TYPES: 'Import-Types',
      IMPORTS_CONFIGURATION: 'Imports Configuration',
      IMPORTS_DASHBOARD_DESCRIPTION: 'Imports Dashboard',
      IMPORTS_DASHBOARD: 'Imports Log',
      IMPORTS_DESCRIPTION: 'Inventories Imports Management',
      IMPORTS_MANAGEMENT: 'Imports Management',
      IMPORTS: 'Imports',
      INFO_WEBSITES: 'Info Websites',
      INGRESS_DESCRIPTION: 'Manage dealer website domain names',
      INGRESS: 'Ingress',
      INVENTORIES_DESCRIPTION: 'Inventory Management',
      INVENTORIES: 'Inventory',
      INVENTORY_LISTING_360: 'Inventory Listing 360',
      INVENTORY_LISTING: 'Inventory Listing',
      INVENTORY_OPERATIONS: 'Inventory Operations',
      INVENTORY_VDP: 'Inventory VDP',
      INVOICING_SERVICE: 'Support Vehicle Invoicing Service',
      LEAD_EXPORT_CONFIGURATION: 'Leads Export',
      LEAD_EXPORT_DESCRIPTION: 'Manage leads export FTP configuration',
      LEADS: 'Leads',
      NEW_CAR_TRIM_DESCRIPTION: 'Update new car trim',
      NEW_CAR_TRIM: 'New Car Trim',
      OEM_MANAGEMENT_DESCRIPTION: 'Manage the order of catalog models',
      OEM_MANAGEMENT: 'Oem Catalog Management',
      OPERATIONS_SHORT: 'OPS',
      OPERATIONS: 'Operations',
      PHONETRACK_LOGS: 'Call Log',
      PHONETRACK_NUMBERS: 'Configured Numbers',
      PRIVACY360: 'Privacy 360',
      RECAPTCHA: 'Google reCAPTCHA',
      REPORT_GEN: 'Reporting',
      REPORTING: 'Reporting',
      REPORTS_DESCRIPTION: 'View dealership reports',
      REPORTS: 'Reports',
      SERVICE_CONFIGURATION_DESCRIPTION: 'Manage Service import configurations',
      SERVICE_CONFIGURATION: 'Service Import Configuration',
      SETTINGS: 'Settings',
      SHOWROOM_AND_DESKING_INTEGRATIONS_SETTINGS_DESCRIPTION: 'Manage the integrations settings',
      SHOWROOM_AND_DESKING_INTEGRATIONS_SETTINGS: 'Integrations Settings',
      SHOWROOM_AND_DESKING_SERVICES: 'Showroom and Desking Services',
      SHOWROOM360: 'Showroom 360',
      SMS_LOGS: 'SMS Log',
      SMS_NUMBERS: 'SMS Numbers',
      STYLES_MANAGEMENT: 'Styles Management',
      SUBSCRIPTIONS: 'Subscriptions',
      SUMMARY_PAGE: 'Summary Page',
      SUPER_ADMIN: 'Super Admin Tools',
      SURVEYS: 'Surveys',
      TASKS: 'Tasks',
      THIRDPARTY_CONFIGURATION: 'Thirdparty Configuration',
      THIRDPARTY_DEALER_DESCRIPTION: 'Manage Thirdparty Dealer',
      THIRDPARTY_DEALER: 'Thirdparty Dealer Configuration',
      THIRDPARTY_LEADS_HISTORY_DESCRIPTION: 'Thirdparty Leads History',
      THIRDPARTY_LEADS_HISTORY: 'Thirdparty Leads History',
      THIRDPARTY_PUSH_DESCRIPTION: 'Manage Thirdparty Push',
      THIRDPARTY_PUSH_HISTORY_DESCRIPTION: 'Manage Thirdparty Push History',
      THIRDPARTY_PUSH_HISTORY: 'Thirdparty Push History',
      THIRDPARTY_PUSH: 'Thirdparty Push Configuration',
      TOOLS: 'Tools',
      USER_LOOKUP_DESCRIPTION: 'Consult user informations',
      USER_LOOKUP: 'User Infos',
      USERS_DESCRIPTION: 'User and rights management',
      USERS: 'Users',
      VEHICLE_UPDATE_ACTION_DESCRIPTION: 'Update all vehicles in a specific inventory',
      VEHICLE_UPDATE_ACTION: 'Vehicle Update Actions',
      VEHICLES_DESCRIPTION: 'Vehicles Inventory',
      VEHICLES: 'Vehicles',
      VIRTUALTOURS_DESCRIPTION: 'Set Evox ID for Virtual Tours',
      VIRTUALTOURS: 'Virtual Tours',
      WEBSITES_MANAGEMENT_SHORT: 'WEB',
      WEBSITES_MANAGEMENT: 'Websites Management',
      WEBSITES: 'Web Sites',
      DUPLICATE_WEBSITES: 'Duplicate Websites'
    },
    oauth: {
      TITLE: 'oAuth Synchronization',
      CLOSE: 'You can close this tab or go back to the homepage.',
      SYNCING: 'Synchronizing... Do not close this tab.',
      SYNC_SUCCESS: 'Your email inbox has been successfully synchronized with CRM.',
      SYNC_ERROR: 'An error occurred when setting up the oAuth connection.',
      MISSING_PARAMETERS: 'oAuth connection could not be done because no authorization code was provided.'
    }
  },
  selectOrganization: {
    INSTRUCTIONS: 'To begin, please search for an Organization or a Dealer.',
    PLACEHOLDER: '...',
    CTA_CHANGE: 'Change Organization'
  },
  apiError: {
    getActivity: 'An error occurred while fetching the activity informations.',
    getEmailByActivityId: 'An error occurred while fetching the email informations.'
  },
  crm: {
    SUGGESTIONS: 'Suggestions',
    OTHERS: 'Others',
    users: {
      OWNER: 'Owner',
      SALESPERSON: 'Salesperson',
      SALES_MANAGER: 'Sales Manager',
      BUSINESS_MANAGER: 'Business Manager'
    }
  },
  deskingCommon: {
    currency: {
      prefix: '$ ',
      suffix: ''
    },
    emptyGarage: {
      add: 'Add',
      buttonLabel: 'Start',
      configure: 'Design Your Vehicle',
      new: 'New',
      newVehicle: 'Search New Inventory',
      subtitle: 'What type of vehicle is your customer interested in?',
      title: 'Your customer is not associated with any vehicle.',
      unlistedVehicle: 'Unlisted Vehicle',
      unlistedVehicleNew: 'Unlisted Vehicle (New)',
      unlistedVehicleUsed: 'Unlisted Vehicle (Used)',
      used: 'Used',
      usedVehicle: 'Search Used Inventory'
    },
    error: {
      addCommissionAdjustment: 'An error occurred while trying to add the commission',
      addDealJacketForm: 'An error occurred when trying to add a new form.',
      allocateEquity: 'An error occurred while trying to allocate the equity',
      api: 'An error occurred when trying to reach the server.',
      assignLead: 'An error occurred when trying to assign the lead',
      bookFNIItems: 'An error occurred when trying to book FNI items.',
      commissionAmountValidation: 'An error occurred while trying to validate the commission amount',
      createDeal: 'An error occurred when trying to create the deal.',
      createDMSAccount: 'An error occurred when trying to create DMS account for contact.',
      createItem: 'An error occurred when trying to create an item.',
      createQuote: 'An error occurred when trying to create the quote.',
      deleteCommissionAdjustment: 'An error occurred while trying to delete the commission adjustment',
      deleteDeal: 'An error occurred when trying to delete the deal.',
      deleteDealJacketForm: 'An error occurred when trying to delete a form.',
      deleteDealJacketForms: 'An error occurred when trying to delete forms.',
      forbidden: 'No access to Desking.',
      getAccounting: 'An error occurred when trying to fetch accounting information.',
      getCommission: 'An error occurred when trying to fetch the commission information.',
      getContractValidationDecision: 'An error occurred when trying to fetch the contract validation decision.',
      getCreditApplicationDecision: 'An error occurred when trying to fetch the credit application decision.',
      getCreditApplicationWidget: 'An error occurred when trying to fetch the Credit application widget.',
      getCustomer: 'An error occurred when trying to fetch the customer.',
      getDealerInfo: 'An error occurred when trying to get the dealer info.',
      getDealersList: 'An error occurred when trying to get the dealers list.',
      getFilters: 'An error occurred when trying to fetch the filters data.',
      getFNIItems: 'An error occurred when trying to fetch a list of items from FNI.',
      getForms: 'An error occurred when trying to get the forms list.',
      getGarage: 'An error occurred when trying to fetch garage data.',
      getItems: 'An error occurred when trying to fetch a list of items.',
      getLeadInfo: 'An error occurred when trying to get the users assigned to the lead.',
      getMatches: 'An error occurred when trying to fetch the match list.',
      getModel: 'An error occurred when trying to fetch model data.',
      getModelYears: 'An error occurred when trying to fetch the list of available years.',
      getPdfFilesList: 'An error occurred when trying to get the pdf files list.',
      getQuote: 'An error occurred when trying to fetch quote data.',
      getRights: 'An error occurred when trying to fetch access rights.',
      getTradeIn: 'An error occurred when trying to fetch trade-in informations.',
      getTradeInWidget: 'An error occurred when trying to fetch the trade-in widget.',
      getUnlistedVehicleMakes: 'An error occurred when trying to get the list of makes for an unlisted vehicles.',
      getUnlistedVehicleModels: 'An error occurred when trying to get the list of models for an unlisted vehicles.',
      getUnlistedVehicleTrims: 'An error occurred when trying to get the list of trims for an unlisted vehicles.',
      getVehicles: 'An error occurred when trying to fetch the vehicle list.',
      hasDeskingAccess: 'An error occurred when trying to fetch access rights.',
      initializeCalculatedAdjustments: 'An error occurred while trying to initialize calculated adjustments',
      linkDMSAccount: 'An error occurred when trying to set DMS account to contact.',
      loadContactSummary: 'An error occurred when trying to fetch the contact information.',
      loadDealJacketForm: 'An error occurred when trying to load a form.',
      loadDMSAccountMatches: 'An error occurred when trying to fetch DMS accounts matches.',
      loadDMSCustomer: 'An error occurred when trying to fetch DMS customer infos.',
      loadIncentives: 'An error occurred while trying to load the incentive',
      msdRoll: 'An error occurred when trying to fetch the MSD roll.',
      openPdfFile: 'An error occurred when trying to open the pdf file to print.',
      openUnlistedInDesking: 'An error occurred when trying to open unlisted vehicle in Desking.',
      printForm: 'An error occurred when trying to print a form.',
      resetValidation: 'An error occurred while trying to reset the validation information',
      selectDealer: 'An error occurred when trying to select the dealer.',
      selectVehicle: 'An error occurred when trying to select the vehicle.',
      selectVehicleMatch: 'An error occurred when trying to select a vehicle match.',
      sendToSerti: 'An error occurred while trying to transfer the quote to Serti.',
      setCustomerVisibility: 'An error occurred when trying to set the quote visible to customer.',
      setIncentive: 'An error occurred while trying to update the incentive',
      setItemFlags: 'An error occurred when trying to set the item flags ',
      setPreferred: 'An error occurred when trying to set the quote as preferred.',
      setQuoteFlags: 'An error occurred when trying to set the quote flags ',
      submitRDR: 'An error occurred while trying to submit RDR',
      tryLater: 'Oops! An error occurred, please try again later!',
      unexpected: 'Unexpected error.',
      unknown: 'Unknown error.',
      updateCommission: 'An error occurred when trying to update the commission information',
      updateContact: 'An error occurred when trying to update this contact.',
      updateCustomer: 'An error occurred when trying to update the customer.',
      updateFinancingDates: 'An error occurred when trying to update the dates.',
      updateFNIItemPlanCoverageParameters: 'An error occurred when trying to change a FNI item coverage parameter.',
      updateFNIItemPlanPricing: 'An error occurred when trying to change a FNI item pricing.',
      updateForms: 'An error occurred when trying to change a form.',
      updateQuote: 'An error occurred when trying to update the quote.',
      uploadDealJacketForm: 'An error occurred when trying to upload a form.',
      validateAmountToPayRollChange: 'An error occurred while trying to validate the total amount to pay adjustment',
      validateDueAtDeliveryRollChange: 'An error occurred while trying to validate the amount due at delivery adjustment',
      validateFNIItem: 'An error occurred when trying to pick a FNI item.',
      validateItem: 'An error occurred when trying to pick an item.',
      validateModel: 'An error occurred when trying to validate the choice.',
      validatePaymentChange: 'An error occurred while trying to validate the payment change',
      validateProfitChange: 'An error occurred while trying to validate the new selling price',
      validateProfitRollChange: 'An error occurred while trying to validate the profit adjustment',
      validation: 'Your change has not been approved.',
      modal: {
        hideDetails: 'Hide Details',
        showDetails: 'Show Details',
        title: 'Error'
      },
      statusActions: {
        lead: 'An error occurred while trying to update the status of the associated lead.',
        quote: 'An error occurred while trying to update the status of the quote.',
        vehicle: 'An error occurred while trying to update the status of the associated vehicle.'
      }
    },
    fieldRequired: 'This field is required',
    fieldsMissing: 'Not all required fields are filled',
    garage: {
      addQuote: 'Create New Quote',
      addVehicle: 'Add a Vehicle',
      deletedMessage: 'This vehicle has been deleted',
      delivered: 'Delivered',
      expired: 'Expired',
      headerTitle: "Client's Garage",
      onHold: 'On Hold',
      sold: 'Sold',
      soldMessage: 'This vehicle is no longer available',
      vehicleResult: 'Vehicle'
    },
    lastUpdated: 'Last updated: ',
    matchesVehicles: {
      addedOption: 'Added Option',
      addedOptions: 'Added Options',
      backToSummary: 'Back to Summary',
      demo: 'Demo',
      extendedWarranty: 'Extended Warranty',
      inTransit: 'In Transit',
      inventory: 'Go to Inventory',
      newAdded: 'New Dealer Items or Accessories Added',
      onHold: 'On Hold',
      onHoldMessage: 'This vehicle is reserved by another quote',
      onOrder: 'On Order',
      optionsDetailModal: {
        code: 'Code',
        description: 'Description',
        noData: 'no option',
        price: 'Price',
        seeDetails: 'See option details',
        title: 'Option Details'
      },
      perfect: 'Perfect Match',
      perfect_plural: '{{count}} Perfect Matches!',
      perfectTitle: 'Great news! We found a perfect match in the inventory!',
      perfectTitle_plural: 'Great news! We found {{count}} perfect matches in the inventory!',
      select: 'Select',
      similar: 'Similar Vehicle Ready to Be Delivered',
      similar_plural: 'Similar Vehicles Ready to Be Delivered',
      similarTitle: 'We have a similar vehicle',
      similarTitle_plural: 'We have {{count}} similar vehicles',
      sold: 'Sold',
      wholesale: 'Wholesale'
    },
    offerDetails: 'Offer Details',
    paymentBar: {
      apr: 'Rate',
      cashDown: 'Initial Cashdown',
      cashDownShort: 'Down',
      customTerm: 'Add Custom Term',
      estimatedValue: 'Estimated Value',
      frequency: 'Frequency',
      kmPerYearPlan: 'Kilometer Plan',
      lien: 'Trade-in Lien',
      limitWarning: {
        and: 'and',
        msg: 'The payment must be between'
      },
      payment: 'Desired Payment',
      rebateSub: 'Includes a total rebate of ',
      revised: 'Revised',
      salesDeposit: 'Sales Deposit',
      securityDeposit: 'Security Deposit',
      taxesIncluded: 'Include Taxes',
      taxRebateLabel: 'Add rebate taxes to desired payment',
      term: 'Term',
      title: 'Adjust Your Payments',
      totalInterestToPay: 'Total Interest to Pay',
      tradeIn: 'Trade-in'
    },
    paymentFrequency: {
      12: 'Monthly',
      24: 'Semi-monthly',
      26: 'Bi-weekly',
      52: 'Weekly'
    },
    purchaseMethod: {
      cash: 'Cash',
      finance: 'Finance',
      lease: 'Lease'
    },
    quoteStatus: {
      approval: 'Approval',
      booked: 'Booked',
      cancelled: 'Cancelled',
      delivered: 'Delivered',
      quote: 'In Progress',
      sold: 'Sold'
    },
    rate: {
      prefix: '% ',
      suffix: ''
    },
    selectionCategories: {
      accessory: 'Accessory',
      accessoryPlural: 'Accessories',
      option: 'Option',
      optionPlural: 'Options'
    },
    selectionConflicts: {
      apply: 'Apply',
      chooseOneOfAvailable: 'Choose 1 of {{availableOptions}} Available Configurations Below',
      configuration: 'Configuration',
      discard: 'Discard',
      head: 'This Choice Conflicts with Other Options',
      optionToAdd: 'Option to Be Added',
      optionToAdd_plural: 'Options to Be Added',
      optionToRemove: 'Option to Be Removed',
      optionToRemove_plural: 'Options to Be Removed',
      plusTaxes: '+ Taxes',
      title: 'Conflicts',
      totalChange: 'Total Change'
    },
    selectionOptions: {
      close: 'Close',
      description: 'Description',
      edit: 'Edit',
      filterPlaceholder: 'Type here to filter items',
      msrpAdjust: 'MSRP Adjust.: ',
      noItemsFound: 'No Results Found',
      optionsTitle: 'Edit {{category}}',
      preview: 'Preview',
      price: 'Suggested Retail Price',
      selectedItems: 'Selected Items',
      total: 'Retail'
    },
    status: 'Status',
    statusBar: {
      backToCustomerGarage: "Back to {{customerName}}'s Garage",
      backToGarage: 'Back to Garage',
      documentPrint: {
        preferredLanguage: 'Preferred Language'
      },
      manufacturerNotes: 'Manufacturer Notes',
      modal: {
        buttonApply: 'Open PDF',
        buttonClose: 'Cancel',
        field_one: '{{count}} field',
        field_other: '{{count}} fields',
        select: 'Select',
        title: 'Complete Fields'
      },
      offerSheet: 'Detailed Offer Sheet'
    },
    switchIsVisibleToCustomer: 'Share with customer',
    table: {
      result: 'result',
      results: 'results'
    },
    taxIn: 'Tax In',
    taxOut: 'Tax Out',
    term: {
      and: 'to',
      editorTitle: 'Enter new term',
      editValue: 'Edit Value',
      for: 'for',
      helper: 'Enter number of months from',
      months: 'Months',
      suffix: 'Months',
      termThousandSeparator: ',',
      up: 'up'
    },
    unlistedVehicle: {
      back: 'Back',
      kms: 'KMs',
      make: 'Make:',
      model: 'Model:',
      new: 'New',
      openInDesking: 'Open In Desking',
      sellingPrice: 'Selling Price:',
      trim: 'Trim:',
      unlistedVehicle: 'Unlisted Vehicle',
      used: 'Used',
      year: 'Year:'
    },
    vehicle: {
      allFeatures: 'All the Features',
      bodyTrimCode: 'Model:',
      certified: 'Certified',
      demo: 'Demo',
      downPayment: 'Down Payment',
      editValue: 'Edit Value',
      extendedWarranty: 'Extended Warranty',
      exterior: 'Exterior',
      incentiveProgram: 'Incentive Program',
      included: 'Included',
      inspected: 'Inspected',
      interior: 'Interior',
      km: 'Km',
      kmPerYearPlan: 'Kilometer Plan',
      kmYear: 'Km / Year',
      make: 'Make',
      model: 'Model',
      new: 'New',
      newInventory: 'New Inv.',
      noDateTitle: 'no date',
      noDescription: 'no description',
      onDelivery: 'On Delivery',
      rebate: 'Rebate Included',
      stockNumber: 'Stock #',
      title: {
        cash: 'Selling Price',
        finance: 'Financing At',
        financeWithBalloon: 'Financing at',
        lease: 'Leasing At'
      },
      tradeIn: 'Trade-in',
      trim: 'Trim',
      used: 'Used',
      vehicleLocation: 'This vehicle is at',
      vehicleNotMapped: 'This vehicle has not been mapped to a stock unit yet',
      vinLabel: 'VIN:',
      wholesale: 'Wholesale',
      year: 'Year'
    },
    vehicleNotFoundText: 'This vehicle is {{status}}. Please select another.',
    vehicleStatus: {
      DELIVERED: 'Delivered',
      FOR_SALE: 'For Sale',
      ON_HOLD: 'On Hold',
      SOLD: 'Sold'
    }
  }
};